<template>
    <div class="page-settle-account g-page">
        <div class="wp">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path:'/'}">首页</el-breadcrumb-item>
                <el-breadcrumb-item>我的购物车</el-breadcrumb-item>
            </el-breadcrumb>

            <div class="step-list">
                <div class="item active">
                    <div class="icon">
                        <img src="@/assets/img/step1.png" alt="">
                    </div>
                    <p> 1.查看购物车</p>
                </div>
                <div class="item cursorPointer" :class="step >= 3 ? 'active' : ''" @click="step = 2">
                    <div class="icon">
                        <img src="@/assets/img/step2.png" v-if="step === 2" alt="">
                        <img src="@/assets/img/step1.png" v-if="step >= 3" alt="">
                    </div>
                    <p> 2.确认订单信息</p>
                </div>
                <div class="item" :class="step >= 4 ? 'active' : ''">
                    <div class="icon">
                        <img src="@/assets/img/step2.png" v-if="step === 3" alt="">
                        <template v-else>
                            <img src="@/assets/img/step1.png" v-if="step >= 4" alt="">
                            <img src="@/assets/img/step3.png" v-else alt="">
                        </template>
                    </div>
                    <p>3.选择结算方式</p>
                </div>
                <div class="item" :class="step >= 5 ? 'active' : ''">
                    <div class="icon">
                        <img src="@/assets/img/step1.png" alt="" v-if="step === 5">
                        <img src="@/assets/img/step3.png" alt="" v-else>
                    </div>
                    <p>4.提交成功</p>
                </div>
            </div>
            <template v-if="step === 2">
                <div class="box">
                    <div class="box-tit">
                        <h3>确认收货地址</h3>
                        <a v-if="goodsAddressInfo" href="javascript:;" @click="iseditgoodsAddress=true,showAdd = true">编辑 </a>
                        <a href="javascript:;" @click="showPick = true">更多地址>></a>
                    </div>
                    <div class="box-row">
                        <p v-if="goodsAddressInfo">{{ goodsAddressInfo.username }} {{ goodsAddressInfo.phone }} {{ goodsAddressInfo.provinceName }} {{ goodsAddressInfo.cityName }} {{ goodsAddressInfo.districtName }} {{ goodsAddressInfo.address }}</p>
                        <p v-else class="c-666 cursorPointer" @click="iseditgoodsAddress=false,showAdd = true">新增地址 <i class="el-icon-circle-plus-outline"></i></p>

                    </div>
                </div>
                <div class="box">
                    <div class="box-tit">
                        <h3>开票信息</h3>
                    </div>
                    <div class="bill-info">
                        <el-radio-group v-model="radio">
                            <el-radio :label="0">增值税普通发票</el-radio>
                            <el-radio :label="1">增值税专用发票</el-radio>
                        </el-radio-group>
                        <div class="row">
                            <div class="label">开票公司：</div>
                            <div class="con">
                                <el-select @change="corporateChange" v-model="companyName" placeholder="请选择单位" disabled>
<!--                                    <el-option-->
<!--                                        v-for="(item,index) in billinfoList"-->
<!--                                        :key="item.id"-->
<!--                                        :label="item.companyName"-->
<!--                                        :value="item">-->
<!--                                    </el-option>-->
                                </el-select>
                            </div>
                        </div>
                        <div v-if="billinfoDetail">
                            <div class="row">
                                <div class="label">单位名称:</div>
                                <div class="con">{{ billinfoDetail.companyName }}</div>
                            </div>
                            <div class="row">
                                <div class="label">纳税人识别码:</div>
                                <div class="con">{{ billinfoDetail.taxpayerIdentificationNumber }}</div>
                            </div>
                            <div class="row">
                                <div class="label">注册地址</div>
                                <div class="con">{{ billinfoDetail.registeredAddress }}</div>
                            </div>
                            <div class="row">
                                <div class="label">注册电话:</div>
                                <div class="con">{{ billinfoDetail.registrationTelephone }}</div>
                            </div>
                            <div class="row">
                                <div class="label">开户银行:</div>
                                <div class="con">{{ billinfoDetail.depositBank }}</div>
                            </div>
                            <div class="row">
                                <div class="label">银行账户:</div>
                                <div class="con">{{ billinfoDetail.bankAccountNumber }}</div>
                            </div>
                        </div>

                    </div>

                </div>
                <div class="box">
                    <div class="box-tit" style="border-bottom: none">
                        <h3>确认收票邮箱</h3>
<!--                        <a v-if="goodsAddressInfo" href="javascript:;" @click="iseditbillAddress=true,billshowAdd = true">编辑 </a>-->
<!--                        <a href="javascript:;" @click="billshowPick = true">更多地址>></a>-->
                    </div>
                    <el-input placeholder="请输入收票邮箱" v-model="receiptAddress"></el-input>

<!--                    <div class="box-row">-->
                        <!--                        <p v-if="billAddressInfo">{{ billAddressInfo.username }} {{ billAddressInfo.phone }} {{ billAddressInfo.provinceName }} {{ billAddressInfo.cityName }} {{ billAddressInfo.districtName }} {{ billAddressInfo.address }}</p>-->
<!--                        <p v-else class="c-666 cursorPointer" @click="iseditbillAddress=false,billshowAdd = true">新增地址 <i class="el-icon-circle-plus-outline"></i></p>-->
<!--                    </div>-->
                </div>
                <div class="box">
                    <div class="box-tit">
                        <h3>确认订单信息</h3>
                    </div>
                    <div class="g-table-wrap">
                        <table class="g-table">
                            <thead>
                            <tr>
                                <td>产品编号</td>
                                <td>产品名称</td>
                                <td>品牌</td>
                                <td>CAS号</td>
                                <td>包装规格</td>
                                <td>售价(￥)</td>
                                <td>会员价(￥)</td>
                                <td>库存</td>
                                <td>订购量</td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item,index) in orderGoodslist" :key="index">
                                <td>
                                    <p>{{ item.goodsNumber }}</p>
                                </td>
<!--                                <td>-->
<!--                                    <p v-html="item.describeText"></p>-->
<!--                                    <p v-if="item.dangerNature"><span class="gray">监管条件：</span> <span class="red">{{ item.dangerNature }}</span></p>-->
<!--                                </td>-->
                                <td>
                                    {{item.name}} {{item.nameEn}}
                                </td>
                                <td>
                                    {{ item.brandName}}
                                </td>
                                <td>
                                    <div class="ftwt">{{ item.casNumber }}</div>
                                </td>
                                <td>
                                    {{ item.specification }}
                                </td>
                                <td>{{ item.customerPrice < item.discountPrice ? item.customerPrice: item.customerPrice > item.discountPrice ? item.discountPrice : item.normalPrice }}</td>
                                <td>{{item.normalPrice}}</td>
                                <td>
                                    <p v-for="(item1,index1) in item.goodsStockList" :key="index1">{{ item1.stockName }}
                                        {{ item1.goodsCount > 50?'>50':item1.goodsCount }}</p>
                                </td>
                                <td>
                                    {{item.count}}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="box-row total">
                        <div class="left">
                            <span>积分</span>
                            返 <em>{{ integral }}</em> 积分
                        </div>
                        <div class="right">
                            <div class="item">
                                产品总金额
                                <em>¥{{totalprice}}</em>
                            </div>
                            <div class="item">
                                运费
                                <em>¥{{freight}}</em>
                            </div>
                            <div class="item">
                                应付总金额
                                <em>¥{{Math.round(( freight + totalprice)*100)/100 }}</em>
                            </div>
                        </div>
                    </div>
                    <div class="remark">
                        <div class="label">备注：</div>
                        <el-input placeholder="请输入内容" v-model="remarks"></el-input>
                    </div>
                </div>
                <div class="bot-btn">
                    <div class="g-btn" @click="clicknext">下一步</div>
                </div>
            </template>
            <template v-if="step === 3 || step === 4">
                <div class="box">
                    <div class="box-tit">
                        <h3>确认订单信息</h3>
                    </div>
                    <div class="g-table-wrap">
                        <table class="g-table">
                            <thead>
                            <tr>
                                <td>产品编号</td>
                                <td>产品描述</td>
                                <td>品牌</td>
                                <td>CAS号</td>
                                <td>包装规格</td>
                                <td>售价(￥)</td>
                                <td>会员价(￥)</td>
                                <td>库存</td>
                                <td>订购量</td>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item,index) in orderGoodslist" :key="index">
                                <td>
                                    <p class="white_space_nowarp">{{ item.goodsNumber }}</p>
                                </td>
                                <td>
<!--                                    <p v-html="item.describeText"></p>-->
	                                <p>{{item.name}} {{item.nameEn}}</p>
	                                <p class="c-1c8f8e">{{item.introduction}}</p>

                                    <p v-if="item.dangerNature"><span class="gray">监管条件：</span> <span class="red">{{ item.dangerNature }}</span></p>
                                </td>
                                <td>
                                    {{ item.brandName}}
                                </td>
                                <td>
                                    <div class="ftwt">{{ item.casNumber }}</div>
                                </td>
                                <td>
                                    {{ item.specification }}
                                </td>
                                <td>{{ item.customerPrice < item.discountPrice ? item.customerPrice: item.customerPrice > item.discountPrice ? item.discountPrice : item.normalPrice }}</td>
                                <td>{{item.normalPrice}}</td>
                                <td>

                                    <p v-for="(item1,index1) in item.goodsStockList" :key="index1">{{ item1.stockName }}
                                        {{ item1.goodsCount > 50?'>50':item1.goodsCount }}</p>
                                </td>
                                <td>
                                    {{item.count}}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="tips">请再次确认收货地址，如果不正确，请返回上级菜单修改!</div>
                    <div class="tips address">{{ goodsAddressInfo.username }} {{ goodsAddressInfo.phone }} {{ goodsAddressInfo.provinceName }} {{ goodsAddressInfo.cityName }} {{ goodsAddressInfo.districtName }} {{ goodsAddressInfo.address }}</div>
                </div>
<!--                账期用户不显示选择支付方式-->
                <div class="box">
                    <div class="box-tit">
                        <h3>确认支付方式</h3>
                        <div class="pay-type">
                            <div class="displayAli_cen" v-if="paymentMethod!=2">
                                <div class="item" v-if="(freight + totalprice) < 5000" :class="payIndex === 0 ? 'active' : '' " @click="payIndex = 0,payType=1">
                                    <div class="icon">
                                        <img class="def" src="@/assets/img/pay1.png" alt="">
                                        <img class="active" src="@/assets/img/pay1-a.png" alt="">
                                    </div>
                                    个人支付
                                </div>
                                <div class="item" :class="payIndex === 1 ? 'active' : '' " @click="payIndex = 1">
                                    <div class="icon">
                                        <img class="def" src="@/assets/img/pay2.png" alt="">
                                        <img class="active" src="@/assets/img/pay2-a.png" alt="">
                                    </div>
                                    对公打款
                                </div>
                            </div>
                            <div class="item" :class="payIndex === 2 ? 'active' : '' " @click="payIndex = 2">
                                <div class="icon">
                                    <img class="def" src="@/assets/img/pay3.png" alt="">
                                    <img class="active" src="@/assets/img/pay3-a.png" alt="">
                                </div>
                                余额抵扣
                            </div>
                        </div>
                    </div>
                    <div class="tips pay1" v-if="payIndex === 0">
                        <div class="mar-15 cursorPointer display juzhong" :class="payType==1?'c-f37500':''" @click="payType = 1">
                            <img src="@/assets/img/wx-pay.png" alt="">
                            微信支付
                        </div>
                        <div class="mar-15 cursorPointer display juzhong" :class="payType==2?'c-f37500':''" @click="payType = 2">
                            <img src="@/assets/img/ali-pay.png" alt="">
                            支付宝支付
                        </div>
                    </div>
                    <div class="tips pay2" v-if="payIndex === 1">
                        <h3>请汇款至 <em>银行账户</em></h3>
                        <p>
                            <span>账户名称:</span>
                            天津一方供应链管理有限公司
                        </p>
                        <p>
                            <span>帐号:</span>
                            442100100100070663
                        </p>
                        <p>
                            <span>开户行:</span>
                            兴业银行天津自由贸易试验区分行
                        </p>
                        <p>
                            <span>银行行号:</span>
                            309110018259
                        </p>
                    </div>
                    <div class="tips pay3" v-if="payIndex === 2">
                        <h3>账户余额</h3>
                        <p>
                            <span>当前预付款余额</span>
                            <em>{{ moneyTotal }}</em>
                        </p>
                    </div>
                </div>

                <div class="box-row total havebtn">
                    <div class="left">
                        <span>积分</span>
                        返 <em>{{ integral }}</em> 积分
                    </div>
                    <div class="right">
                        <div class="item">
                            产品总金额
                            <em>¥{{totalprice}}</em>
                        </div>
                        <div class="item">
                            运费
                            <em>¥{{freight}}</em>
                        </div>
                        <div class="item">
                            应付总金额
                            <em>¥{{Math.round(( freight + totalprice)*100)/100 }}</em>
                        </div>
                        <div class="g-btn" @click="sumbitorderInfo">确定</div>
                    </div>
                </div>

            </template>
            <template v-if="step === 5">
                <div class="exchange-result type2">
                    <div class="pic">
                        <img src="@/assets/img/result-succ.png" alt="">
                    </div>
                    <div class="text">
                        <h3>提交成功!</h3>
                        <p>请前往我的订单进行支付</p>
                    </div>
                    <a href="#/myOrderForGoods" class="g-btn">
                        查看我的订单
                        <img src="@/assets/img/i-arr.png" alt="">
                    </a>
                </div>
            </template>


        </div>

        <pickAddress :provincelist="provincelist" :addressList="addressList" :addressid="goodsAddressInfo?goodsAddressInfo.id:goodsAddressInfo" v-if="showPick" @ConfirmpickAddress="ConfirmpickAddress" @getaddressList="getaddressList"/>
        <addAddress :addressLists="addressList" :provincelist="provincelist" :goodsAddressInfo="goodsAddressInfo" :isedit="iseditgoodsAddress" v-if="showAdd" @getaddressList="getaddressList('update')" @close="showAdd = false" />
        <billpickAddress :provincelist="provincelist" :addressList="billaddressList" :addressid="billAddressInfo?billAddressInfo.id:billAddressInfo" v-if="billshowPick" @ConfirmpickAddress="billConfirmpickAddress" @getbilladdressList="getbilladdressList"/>
        <billaddAddress :provincelist="provincelist" :goodsAddressInfo="billAddressInfo" :isedit="iseditbillAddress" v-if="billshowAdd" @getbilladdressList="getbilladdressList" @close="billshowAdd = false" />
    </div>
</template>

<script>
import addAddress from "@/components/addAddress.vue";
import pickAddress from "@/components/pickAddress.vue";
import billaddAddress from "@/components/billaddAddress.vue";
import billpickAddress from "@/components/billpickAddress.vue";
import {addressList, billaddressList, billInfoList, getregionList, userDetail} from "@/api/account";
import {customerGoodsList,systemConfig,orderInfoSave} from "@/api/transaction";
import Bus from "@/utils/bus";


export default {
    name: "SettleAccounts",
    components:{addAddress,pickAddress,billaddAddress,billpickAddress},
    data() {
        return {
            step: 2,
            radio: 0, // 开票类型
            payIndex: '', // 支付方式选择
            payType:'', // 支付方式 0对公，1微信，2支付宝，3余额

            showPick:false,
            showAdd:false,
            billshowPick:false,
            billshowAdd:false,
            provincelist:[], // 行政区划
            goodsAddressInfo:"", // 收货地址
            iseditgoodsAddress:false, // 是否编辑收货地址
            billAddressInfo:"", // 收发票地址
            iseditbillAddress:false, // 是否编辑收票地址
            billinfoList:[], // 发票信息列表
            billinfoDetail:'', // 选中的单位信息详情
            billinfoId:"",
            companyName:"", //开票选中的单位名称

            goodsIdlist:[], // 所选商品idlist
            goodsNums:[], // 所选商品数量数组
            orderGoodslist:[], // 全部订单商品
            totalprice:0, //总金额
            integral:0, // 积分总数
            freight:30, // 运费
            remarks:'', // 订单备注
            paymentMethod:'', // 2账期客户 !2 预付客户

            moneyTotal:0, // 余额
            receiptAddress:"", // 收票邮箱

        }
    },
    watch:{
        payIndex(n,o){
            console.log('支付方式选择',n)
            this.payType= n==1?0:n==2?3:''
            this.step=4 // 结算方式
        },
    },
    mounted() {
        this.paymentMethod=JSON.parse(localStorage.getItem('customInfo')).paymentMethod
        this.getuserDetail() // 获取用户信息
        this.getCity() // 获取行政区划
        this.getaddressList() // 获取收货地址
        this.getbillinfoList() // 获取发票信息
        this.getbilladdressList() // 获取发票地址
	    this.getfreight() // 获取运费

        if(this.$route.query){
            console.log(this.$route.query)
            this.goodsIdlist=this.$route.query.goodsIdlist
            this.goodsNums=this.$route.query.goodsNums
            this.goodsNums=JSON.parse(decodeURIComponent(this.goodsNums))
	        this.getcustomerGoodsList()
        }
    },
    methods:{
        // 监听开票单位选中
        corporateChange(e){
          console.log(e)
            this.billinfoDetail=e
        },
        // 确认收货地址
        ConfirmpickAddress(e) {
            console.log(e)
            if(e){ // 确认选择地址
                this.goodsAddressInfo=e
            }
            this.showPick = false
        },
        // 确认收票地址
        billConfirmpickAddress(e) {
            console.log(e)
            if(e){ // 确认选择地址
                this.billAddressInfo=e
            }
            this.billshowPick = false
        },
        // 提示
        message(type,title,duration){
            this.$message({
                type: type,
                message: title,
                duration: duration || 2000
            });
        },

        // 点击下一步
        clicknext(){
            if(!this.goodsAddressInfo){ // 未选择收货地址
                this.message('warning',"请选择收货地址！")
                return
            }
            // if(!this.billinfoDetail){ // 未选择发票单位
            //     this.message('warning',"请选择开票公司！")
            //     return
            // }
            // if(!this.billAddressInfo){ // 未选择收发票地址
            //     this.message('warning',"请选择收发票地址！")
            //     return
            // }
            if(!this.receiptAddress){ // 未填写收票邮箱
                this.message('warning',"请填写收票邮箱！")
                return
            }

            if(this.paymentMethod!=2){ // 非账期用户
                this.step = 3
            }else{
                this.step = 4
            }
        },
        //  -------------------------接口请求
        // 提交商品信息
      sumbitorderInfo() {
        let totleprice = Math.round((this.freight + this.totalprice) * 100) / 100
        if (this.payIndex === 2 && (totleprice > this.moneyTotal) && this.paymentMethod != 2) { // 余额支付 并且余额不足 账期用户除外
          this.message('warning', '账户余额不足，请联系客服充值')
          return
        }

        let goodsList = []
        this.orderGoodslist.forEach(i => {
          let price = i.customerPrice < i.discountPrice ? i.customerPrice : i.customerPrice > i.discountPrice ? i.discountPrice : i.normalPrice
          let info = {
            "goodsId": i.id, // 商品id
            "dealPrice": price, // 成交单价
            "quantityPurchase": i.count,  // 商品数量
            "amount": price * i.count, // 商品金额
          };
          goodsList.push(info);
        });
        let params = {
          customerId: localStorage.getItem('customId'), // 客户id
          amount: this.totalprice, // 订单总金额 加运费
          freight: this.freight, // 运费
          deliveryAddress: this.goodsAddressInfo.address, // 收货-详细地址
          deliveryPhone: this.goodsAddressInfo.phone, // 收货-电话
          deliveryRegionCode: this.goodsAddressInfo.regionCode, // 收货-区域代号
          deliveryUsername: this.goodsAddressInfo.username, // 收货-姓名
          // invoiceBankAccountNumber:this.billinfoDetail.bankAccountNumber, //发票-开户行账号
          // invoiceCompanyName:this.billinfoDetail.companyName, //发票-单位名称
          // invoiceDepositBank:this.billinfoDetail.depositBank, //发票-开户银行
          // invoiceRegisteredAddress:this.billinfoDetail.registeredAddress, //发票-注册地址
          // invoiceRegistrationTelephone:this.billinfoDetail.registrationTelephone, //发票-注册电话
          // invoiceTaxpayerIdentificationNumber:this.billinfoDetail.taxpayerIdentificationNumber, //发票-纳税人识别号
          invoiceType: this.radio, //发票-类型 0、1 普通、专用
          invoiceCompanyName: this.companyName, //发票-单位名称
          type: 0, // 0/1 线上订单 线下询价
          receiptAddress: this.receiptAddress, // 收票邮箱
          // receiptAddress:this.billAddressInfo.address, // 收票-详细地址
          // receiptPhone:this.billAddressInfo.phone, // 收票-电话
          // receiptRegionCode:this.billAddressInfo.regionCode, // 收票-行政区编码
          // receiptUsername:this.billAddressInfo.username, // 收票-姓名
          remarks: this.remarks, // 订单备注
          "goodsList": goodsList, // 商品信息
        }
        if (this.paymentMethod != 2) { // 非账期用户
          params.payType = this.payType // 支付方式
        } else {
          params.payType = 3 // 支付方式 账户余额
        }
        console.log('提交订单参数', params)
        orderInfoSave(params).then((res) => {
          if (res.data.code == 200) {
            let data = res.data.data
            console.log(data)
            this.step = 5 // 订单提交成功
            let msg = this.payIndex === 1 ? '提交订单成功，支付请备注订单号' : '提交订单成功'  // 对公打款
            this.message('success', msg, 3000)
            Bus.$emit('addCart', '下单成功刷新购物车数量')
          } else {
            this.message('error', res.data.mag)
          }

        }).catch(error => {
          console.log(error)
          if (error.response.data.code != 200) {
            this.message('error', error.response.data.message)
          }
        })


      },

        getuserDetail(){ // 获取用户信息
            console.log(JSON.parse(localStorage.getItem('userInfo')).userId)
            let userId=JSON.parse(localStorage.getItem('userInfo')).userId
            userDetail({userId:userId}).then((res) => {
                if (res.data.code==200) {
                    let data=res.data.data
                    localStorage.setItem('customInfo', JSON.stringify(res.data.data)); // 登录用户信息
                    localStorage.setItem('customId', res.data.data.id)
	                this.companyName=data.companyName // 单位名称
	                this.receiptAddress=data.email || '' // 邮箱
                    this.moneyTotal=JSON.parse(localStorage.getItem('customInfo')).moneyTotal || 0

                }

            })
        },
        // 获取行政区划
        getCity(){
	        console.log(this.$lazylist)
	        this.provincelist=this.$lazylist
            // getregionList({}).then((res) => {
            //     if (res.data.code==200) {
            //         let data=res.data.data.slice(1)
            //         console.log(data)
            //         this.provincelist=data
            //     }
            // })
        },
        // 获取收货地址列表
        getaddressList(type){
            console.log('获取收货地址列表')
            let customerId=localStorage.getItem('customId')
            addressList({customerId:customerId}).then((res) => {
                if (res.data.code==200 && res.data.data.length>0) {
                    this.addressList=res.data.data
                    this.addressList.forEach(i=>{
                        let array=[]
                        // console.log(i.regionName)
                        array=i.regionName.split('-')
                        i.provinceName=array[0]
                        i.cityName=array[1]
                        i.districtName=array[2]
                        console.log(array)

	                    if(type=='update' && this.goodsAddressInfo){ // 编辑地址
		                    if(i.id==this.goodsAddressInfo.id){ // 匹配当前编辑的地址并重新赋值
			                    this.goodsAddressInfo=i
		                    }
	                    }else{
		                    if(i.isDefault==1){ // 默认地址
			                    this.goodsAddressInfo=i
		                    }
	                    }
                    })
                }else{
                    this.addressList=[]
                    this.goodsAddressInfo=''
                }

            })
        },
        // 获取收发票地址列表
        getbilladdressList(){
            let customerId=localStorage.getItem('customId')
            billaddressList({customerId:customerId}).then((res) => {
                if (res.data.code==200) {
                    this.billaddressList=res.data.data
                    this.billaddressList.forEach(i=>{
                        let array=[]
                        // console.log(i.regionName)
                        array=i.regionName.split('-')
                        i.provinceName=array[0]
                        i.cityName=array[1]
                        i.districtName=array[2]
                        console.log(array)
                    })
                    this.billAddressInfo=this.billaddressList[0]
                }else{
                    this.billaddressList=[]
                    this.billAddressInfo=''
                }

            })
        },
        // 获取发票信息列表
        getbillinfoList(){
            let customerId=localStorage.getItem('customId')
            billInfoList({customerId:customerId}).then((res) => {
                if (res.data.code==200) {
                    this.billinfoList=res.data.data
                }

            })
        },

    //    查询系统配置 运费
        getfreight(){
            // postage_setting查运费
            systemConfig({paramCode:'postage_setting'}).then((res) => {
                if (res.data.code==200) {
                    // console.log(res.data.data)
                    let data=res.data.data
                    this.freight=Number(data.paramValue)
                }

            })
        },

    //    批量获取所选商品订单信息
        getcustomerGoodsList(){
            let parmas={
                customerId:localStorage.getItem('customId'),
                goodsIdList:this.goodsIdlist
            }
            // console.log(this.goodsIdlist)
            // console.log(parmas)
            // return
            customerGoodsList(parmas).then((res) => {
                if (res.data.code==200) {
                    let data=res.data.data
                    console.log(data)
                    let integral=0
                    let totalprice=0
                    data.forEach((i,index)=>{
                        i.describeText=this.$utils.showHtml(i.describeText)
	                    let count=1
	                    console.log(this.goodsNums)
	                    this.goodsNums.forEach(j=>{
	                    	if(i.goodsId == j.goodsId){ // 通过商品id匹配数量
			                    count=j.count
		                    }
	                    })
	                    i.count=count
	                    i.customerPrice=parseFloat(i.customerPrice)>0?parseFloat(i.customerPrice):99999
                        i.discountPrice=parseFloat(i.discountPrice)>0?parseFloat(i.discountPrice):99999
                        i.normalPrice=parseFloat(i.normalPrice)>0?parseFloat(i.normalPrice):99999
                        let price=i.customerPrice < i.discountPrice ? i.customerPrice: i.customerPrice > i.discountPrice ? i.discountPrice : i.normalPrice

                        integral+= Math.trunc(price * i.count)
                        totalprice+=Math.round(price * i.count * 100) / 100
                        // debugger
                    })
                    this.totalprice=Math.round(totalprice * 100) / 100
	                if(this.totalprice>1000) this.freight=0 // 订单金额大于1000 运费为0
                    this.integral=Math.ceil(integral) // 向上取整
                    this.orderGoodslist=data
                }

            })

        },



    },
}
</script>

<style scoped>

</style>
