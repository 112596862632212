<template>
    <div class="m-pop">
        <div class="pop-con" style="max-width: 600px;">
            <div class="title">
                <h3>{{isedit?'编辑':'添加'}}收票地址</h3>
                <div class="close" @click="$emit('close')"></div>
            </div>
            <div class="inner-con add-address-con">
                <div class="login-register-form">
                    <el-form label-position="top" ref="addressForm" :model="addressForm" :rules="addressRules">
                        <el-form-item label="收票人姓名：" prop="name">
                            <div class="con">
                                <el-input v-model="addressForm.name" placeholder="请输入收票人姓名"></el-input>
                            </div>
                        </el-form-item>
                        <el-form-item label="收票地址：" prop="address">
                            <div class="flex">
                                <el-select @change="provinceChange" v-model="addressForm.provinceName" placeholder="请选择省">
                                    <el-option
                                        v-for="(item,index) in provincelist"
                                        :key="item.code"
                                        :label="item.provinceName"
                                        :value="item.provinceName">
                                    </el-option>
                                </el-select>
                                <el-select @change="cityChange" v-model="addressForm.cityName" placeholder="请选择市">
                                    <el-option
                                        v-for="(item,index) in citylist"
                                        :key="item.code"
                                        :label="item.cityName"
                                        :value="item.cityName">
                                    </el-option>
                                </el-select>
                                <el-select @change="districtChange" v-model="addressForm.districtName" placeholder="请选择区">
                                    <el-option
                                        v-for="item in districtlist"
                                        :key="item.code"
                                        :label="item.districtName"
                                        :value="item.districtName">
                                    </el-option>
                                </el-select>
                            </div>
                            <div class="con">
                                <el-input v-model="addressForm.address" placeholder="请输入街道地址"></el-input>
                            </div>
                        </el-form-item>
                        <el-form-item label="手机号：" prop="phone">
                            <div class="con">
                                <el-input type="number" v-model="addressForm.phone" placeholder="请输入手机号"></el-input>
                            </div>
                        </el-form-item>
                    </el-form>
                    <div class="g-btns">
                        <div class="g-btn small" @click="saveAddress()">
                            <img src="@/assets/img/i-save.png" alt="">
                            保存
                        </div>
                        <div class="g-btn small gray" @click="clearForm(),$emit('close')">
                            <img src="@/assets/img/i-cancel.png" alt="">
                            取消
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {billAddresssave, billAddressupdate} from "@/api/account";

export default {
    name: "addAddress",
    props:['provincelist','isedit','goodsAddressInfo'],
    data() {
        return {
            addressForm: {
                name:'',
                phone:"",
                provinceName:'',
                cityName:'',
                districtName:"",
                address:""
            },
            addressRules: {
                name: [{required: true, trigger: 'blur', message: '必填项'}],
                address: [{required: true, trigger: 'blur', message: '必填项'}],
                phone: [{required: true, trigger: 'blur', message: '必填项'}],
            },
            addressList:[], // 收票地址
            addressId:"",

            // provincelist:[],
            citylist:[],
            districtlist:[],
            regionCode:"", // 行政区划编号
        }
    },
    mounted() {
        if(this.isedit){ // 编辑
            console.log(this.goodsAddressInfo)
            this.clickedit(this.goodsAddressInfo)
        }
    },
    methods: {
        // 监听省份选择
        provinceChange(e){
            console.log(e)
            this.provincelist.forEach(i=>{
                if(i.provinceName==e){
                    this.citylist = i.children
                }
            })

            this.districtlist=[]
            this.regionCode=''
            this.addressForm.cityName=''
            this.addressForm.districtName=''
        },
        // 监听城市选择
        cityChange(e){
            console.log(e)
            this.citylist.forEach(i=>{
                if(i.cityName==e){
                    this.districtlist = i.children
                }
            })
            this.regionCode=''
            this.addressForm.districtName=''
        },
        // 监听地区选择
        districtChange(e){
            console.log(e)
            this.districtlist.forEach(i=>{
                if(i.districtName==e){
                    this.regionCode = i.code
                }
            })
        },
        // 点击编辑
        clickedit(item){
            this.addressId=item.id
            this.addressForm= {
                name:item.username,
                phone:item.phone,
                provinceName:item.provinceName,
                cityName:item.cityName,
                districtName:item.districtName,
                address:item.address
            }
            this.provincelist.forEach(i=>{
                if(i.provinceName==item.provinceName){
                    this.citylist = i.children
                    this.citylist.forEach(j=>{
                        if(j.cityName==item.cityName){
                            this.districtlist = j.children
                            this.districtlist.forEach(k=>{
                                if(k.districtName==item.districtName){
                                    this.regionCode = k.code
                                }
                            })
                        }
                    })
                }
            })
            console.log(this.citylist)
            console.log(this.districtlist)
        },
        //保存地址
        saveAddress(){
            if(this.addressId){ // 编辑
                this.addAddress(this.addressId)
            }else{ // 新增
                this.addAddress()
            }
        },
        // 清空表单
        clearForm(){
            this.addressForm={
                name:'',
                phone:"",
                provinceName:'',
                cityName:'',
                districtName:"",
                address:""
            }
            this.addressId=''
            this.regionCode=''
        },


        //  -------------------------接口请求

        // 新增地址
        addAddress(id){
            // debugger
            this.$refs.addressForm.validate(valid => {
                if (valid) {
                    if (!(/^1[3456789]\d{9}$/.test(this.addressForm.phone))) {
                        this.$message({
                            type: 'warning',
                            message: '手机号格式不正确！',
                            duration: 1500
                        });
                        return
                    }
                    if (!this.regionCode) {
                        this.$message({
                            type: 'warning',
                            message: '请选择省市区！',
                            duration: 1500
                        });
                        return
                    }
                    let isDefault= this.addressList.length==0?1:0
                    let param={
                        customerId:localStorage.getItem('customId'), // 登录用户对应的客户的ID
                        username:this.addressForm.name,
                        phone:this.addressForm.phone,
                        address:this.addressForm.address,
                        regionCode:this.regionCode,
                        isDefault:isDefault // 1是0否
                    }
                    let url=billAddresssave
                    if(id){ // 编辑
                        param.id=id
                        url=billAddressupdate
                    }else{
                        url=billAddresssave
                    }
                    url(param).then((res) => {
                        if (res.data.code==200) {
                            this.$message({
                                type: 'success',
                                message: "保存成功",
                                duration: 2000
                            });

                            this.$emit('getbilladdressList') // 重新获取地址列表
                            this.$emit('close') // 关闭弹窗
                            this.clearForm()
                        }else{
                            this.$message({
                                type: 'error',
                                message: res.data.message,
                                duration: 2000
                            });
                        }

                    })
                }
            })

        },

    }
}
</script>

<style lang="scss">
//.addressForGoods.vip-content{
//	.el-select-dropdown .el-popper{
.el-scrollbar .el-select-dropdown__wrap.el-scrollbar__wrap{
    margin-bottom: -15px !important;
    margin-right: -5px !important;
}
//}

//}

</style>
<style scoped>

</style>
